import React, { FC } from "react";
export { Head } from "src/utils/head";

import { Layout } from "components/template/layout";

import { HubContainer } from "./_container";

const HubPage: FC = () => {
  return (
    <Layout>
      <HubContainer />
    </Layout>
  );
};

export default HubPage;
