import React from "react";
// CSS helpers
import styled from "styled-components";

// Components

// Atoms
import { Title } from "components/atoms/title";
import { Button } from "components/atoms/button";

// Images & Videos
import HomeVideo from "assets/video/darkHub.mp4";

// Contract
import abi from "assets/contracts/SOG.json";

import { ethers } from "ethers";
import { useState, useEffect } from "react";

const Container = styled.div``;

interface HorizontalRowProps {
  mt?: number;
}

const HorizontalRow = styled.hr<HorizontalRowProps>`
  margin-right: 80px;
  margin-left: 80px;
  background: ${({ theme }) => theme.colors.gray["400"]};
  height: 1px;
  border-width: 0;
  margin-top: ${({ mt }) => mt ?? 0}px;
`;

const ContentContainer = styled.div`
  height: 320px;
  margin-right: 80px;
  margin-top: 60px;
  margin-left: 80px;
  display: flex;
`;

const Video = styled.video`
  width: 100%;
  border-radius: 4px;
`;

const TicketInfos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 40px;
  width: 160px;
  line-height: 200%;
`;

const RemainingTickets = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: 18px;
  margin-bottom: 40px;
`;

const TextContent = styled.div`
  margin-left: 40px;
  font-family: ${({ theme }) => theme.fontFamily.thin};
  letter-spacing: 0.2em;
  width: 100%;
  line-height: 200%;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;

const CtaContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  align-items: center;
  text-align: center;
  line-height: 200%;
  color: var(--text-color);
  width: 800px;
`;

const Max = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  cursor: pointer;
`;

const TotalPrice = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.thin};
`;

const Alert = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.thin};
  color: ${({ theme }) => theme.colors.red[500]};
`;

export const HubContainer = () => {
  const [balance, setBalance] = useState<string | undefined>();
  const [currentAccount, setCurrentAccount] = useState<string | undefined>();
  const [chainId, setChainId] = useState<number | undefined>();
  const [chainname, setChainName] = useState<string | undefined>();
  const [numberOfTokens, setNumberOfTokens] = useState<number>(1);
  const [minted, setMinted] = useState<boolean>(false);

  const maxTokens = 10;
  const price = 0.03;

  const balanceExceeded: boolean = price * numberOfTokens > Number(balance);

  const contractAddress: string = "0x2a4425631aB27186796a3a2808b0be2Ad35edFf2";

  useEffect(() => {
    // Exit if account already connected
    if (!currentAccount || !ethers.utils.isAddress(currentAccount)) return;
    // Exit if no metamask extension
    if (!window.ethereum) return;

    // Setup provider
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    // Get Balance
    provider.getBalance(currentAccount).then((result) => {
      setBalance(ethers.utils.formatEther(result));
    });

    provider.getNetwork().then((result) => {
      setChainId(result.chainId);
      setChainName(result.name);
    });
  }, [currentAccount]);

  const onClickConnect = async () => {
    //client side code
    if (!window.ethereum) {
      console.log("please install MetaMask");
      return;
    }

    //we can do it using ethers.js
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    // MetaMask requires requesting permission to connect users accounts
    provider
      .send("eth_requestAccounts", [])
      .then((accounts) => {
        if (accounts.length > 0) {
          setCurrentAccount(accounts[0]);
        }
      })
      .catch((e) => console.log(e));

    // console.log({ currentAccount, chainId, balance, chainname });
  };

  const mint = async () => {
    //we can do it using ethers.js
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const signer = provider.getSigner();

    const contract = new ethers.Contract(contractAddress, abi);

    console.log(currentAccount);

    // Look at the balance and see if wallet is able to mint the nfts
    console.log(Number(balance));

    if (!balanceExceeded) {
      // Lancer fonction mint
      setMinted(true);
    }
  };

  const addToken = () => {
    if (numberOfTokens < maxTokens) {
      setNumberOfTokens(numberOfTokens + 1);
    }
  };
  const subToken = () => {
    if (numberOfTokens > 1) {
      setNumberOfTokens(numberOfTokens - 1);
    }
  };

  const max = () => setNumberOfTokens(maxTokens);

  return (
    <Container>
      {/* TITLE */}
      <Title
        type="h1"
        fontSize="100"
        mobilefontsize="3O"
        mt={80}
        align="center"
      >
        CLONE X SOCIETHY
      </Title>

      <HorizontalRow />

      {/* MIDDLE PAGE */}
      <ContentContainer>
        <Video autoPlay muted loop>
          <source src={HomeVideo} type="video/mp4" />
        </Video>

        <TicketInfos>
          <RemainingTickets>36 left</RemainingTickets>
        </TicketInfos>

        {/* <img src={MindMap1} alt="Societhy Hub Image" /> */}
        <TextContent>
          We are creating this experience for the clone X family. Take your
          ticket to be part of the very first event of Societhy x Clone X. This
          will be memorized as the first Web3 community event at the highest
          quality ever seen. Wording wording...
        </TextContent>
      </ContentContainer>
      <HorizontalRow mt={60} />

      {/* FOOTER */}

      {/* CONNECT METAMASK */}
      {!chainId && (
        <Footer>
          <CtaContainer>
            <Button type="primary" onClick={onClickConnect}>
              CONNECT METAMASK
            </Button>
          </CtaContainer>

          <CtaContainer>
            <Button type="secondary" href="/">
              BACK HOME
            </Button>
          </CtaContainer>
        </Footer>
      )}

      {/* CHANGE NETWORK */}
      {currentAccount && chainId && chainId !== 1 && (
        <Footer>
          <CtaContainer>
            You're actually on {chainname} network. First choose Ethereum
            Mainnet !
          </CtaContainer>
        </Footer>
      )}

      {/* MINT */}
      {!minted && currentAccount && chainId === 1 && (
        <Footer>
          <CtaContainer>
            <Button type="secondary" onClick={subToken}>
              -
            </Button>
            <div>{numberOfTokens}</div>
            <Button type="secondary" onClick={addToken}>
              +
            </Button>
            <Max onClick={max}>MAX</Max>
            <div>|</div>
            <TotalPrice>{(price * numberOfTokens).toFixed(2)}eth</TotalPrice>

            <Button
              type={balanceExceeded ? "disabled" : "primary"}
              onClick={mint}
            >
              MINT
            </Button>
          </CtaContainer>
          <CtaContainer>
            You are now connected with the following address : {currentAccount}
          </CtaContainer>

          {balanceExceeded && (
            <Alert>
              Insufficient funds, you only have
              {" " + Number(balance).toFixed(3)} eth
            </Alert>
          )}
        </Footer>
      )}

      {/* MINT SUCCESSFUL */}
      {minted && (
        <Footer>
          <CtaContainer>
            Congrats, you'll soon be contacted for the event.
          </CtaContainer>
          <CtaContainer>
            <Button type="secondary">CHECK COLLECTION</Button>
          </CtaContainer>
        </Footer>
      )}
    </Container>
  );
};
